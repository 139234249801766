import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .quantity_pdf {
    h2 {
      text-transform: uppercase;
      color: #312f2f;
      padding-bottom: 10px;
    }

    input {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: #fb9237;
      font-weight: bold;

      &::placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 1px solid #fb9237;
        &::placeholder {
          color: #fb9237;
        }
      }
    }
  }
`;

export const Etiqueta = styled.div`
  background-color: #fff;
  margin-top: 50px;
  box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  padding: 40px;
`;

export const SubTitle = styled.div`
  padding-top: 20px;

  > p {
    padding-top: 5px;
    font-size: 17px;
  }
`;

export const Barcode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;
