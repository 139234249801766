import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import PropTypes from 'prop-types';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { AiOutlineFilePdf, AiOutlineArrowLeft } from 'react-icons/ai';
import api from '../../services/api';
import PDFCreate from './PDFCreate';

import { Container, Back } from './styles';

function PDFExport({ quantityPDF }) {
  const { token } = useParams();

  const [invoice, setInvoice] = useState([]);
  const [invoiceName, setInvoiceName] = useState([]);
  const [invoicePartner, setInvoicePartner] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [productDate, setProductDate] = useState();

  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    async function loadInvoice() {
      const response = await api.get(`/invoices/${token}/`);
      const { data } = response;
      setInvoice(data);
      setInvoiceName(data.company_id);
      setInvoicePartner(data.partner_id);
      setShipping(data.transportadora_id);

      const formattedDate = format(
        new Date(data.data_emissao),
        "dd'/'MM'/'yyyy'"
      );
      setProductDate(formattedDate);

      setLoadingButton(true);
    }

    loadInvoice();
  }, [token]);

  return (
    <Container>
      {loadingButton ? (
        <button>
          <AiOutlineFilePdf size="1.5em" style={{ color: '#fff' }} />
          <PDFDownloadLink
            document={
              <PDFCreate
                invoice={invoice}
                invoiceName={invoiceName}
                invoicePartner={invoicePartner}
                weight={invoice.peso_bruto}
                quantityPDF={quantityPDF}
                shipping={shipping}
                productDate={productDate}
              />
            }
            fileName="EtiquetaProduto.pdf"
            style={{ textDecoration: 'none', color: '#fff', width: '120px' }}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Download PDF'
            }
          </PDFDownloadLink>
        </button>
      ) : (
        ''
      )}

      <Back>
        <a href="/">
          <AiOutlineArrowLeft size="30px" color="#535353" />
        </a>
        <a href="/">Voltar</a>
      </Back>
    </Container>
  );
}

PDFExport.propTypes = {
  quantityPDF: PropTypes.element.isRequired,
};

export default PDFExport;
