import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import ProductLabel from '../pages/ProductLabel';
import Invoice from '../pages/Invoice';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
      </Switch>
      <Switch>
        <Route path="/produto/:token" exact component={ProductLabel} />
      </Switch>
      <Switch>
        <Route path="/notafiscal/:token" exact component={Invoice} />
      </Switch>
    </BrowserRouter>
  );
}
