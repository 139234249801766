import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Logo = styled.div`
  img {
    width: 220px;
  }
`;

export const Title = styled.h1`
  padding-top: 100px;
  color: #312f2f;
  text-transform: capitalize;
  text-align: center;
`;

export const Buttons = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 30%;

  button {
    margin-top: 20px;
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 5px;
  }

  #btn_product {
    background-color: #fbaa51;
    transition: 0.3s;
    color: #fff;

    :hover {
      background-color: #f89b36;
    }
  }

  #btn_note {
    background-color: #8f0df5;
    transition: 0.3s;
    color: #fff;

    :hover {
      background-color: #7c00dd;
    }
  }
`;
