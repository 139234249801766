import React from 'react';

import PropTypes from 'prop-types';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

function PDFCreate({
  data,
  productTmpl,
  productBarcode,
  productDate,
  barcodeNull,
  quantityPDF,
  companyName,
}) {
  let canvas;
  canvas = document.createElement('canvas');
  JsBarcode(canvas, `${productBarcode.barcode}`, {
    width: 2,
    height: 40,
  });
  const barcode = canvas.toDataURL();

  function QuantityPDF() {
    let array = [];
    for (let i = 1; i <= quantityPDF; i += 1) {
      array.push(
        <Page size="B8" height="200" orientation="landscape" object-fit="fill">
          <View style={styles.body(barcodeNull)}>
            <Text style={styles.title}>{companyName}</Text>
            <View style={styles.subTitle(barcodeNull)}>
              <Text>Produto: {productTmpl.name} </Text>
              <Text>Quantidade/Lote: {data.qty_done}</Text>
            </View>
            <View style={styles.group}>
              {barcodeNull !== null && (
                <Image src={barcode} style={styles.barcode} />
              )}
              <Text>Data de Emissão: {productDate}</Text>
            </View>
          </View>
        </Page>
      );
    }
    return array.map((pagEtiqueta) => pagEtiqueta);
  }

  return <Document>{QuantityPDF()}</Document>;
}

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: (barcodeNull) => ({
    paddingTop: barcodeNull ? 20 : 40,
    paddingBottom: 40,
    paddingHorizontal: 20,
    width: '100%',
  }),

  title: {
    textTransform: 'uppercase',
    fontSize: 12,
  },

  subTitle: (barcodeNull) => ({
    paddingTop: barcodeNull ? 10 : 15,
    fontSize: 8,
  }),

  barcode: {
    width: 60,
    height: 40,
  },

  group: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 8,
    paddingTop: 20,
  },
});

export default PDFCreate;

PDFCreate.propTypes = {
  data: PropTypes.element.isRequired,
  productTmpl: PropTypes.element.isRequired,
  productBarcode: PropTypes.element.isRequired,
  productDate: PropTypes.element.isRequired,
  barcodeNull: PropTypes.element.isRequired,
  quantityPDF: PropTypes.element.isRequired,
  companyName: PropTypes.element.isRequired,
};
