import React from 'react';

import PropTypes from 'prop-types';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

function PDFCreate({
  invoice,
  invoiceName,
  invoicePartner,
  quantityPDF,
  weight,
  shipping,
  productDate,
}) {
  function QuantityPDF() {
    let array = [];
    for (let i = 1; i <= quantityPDF; i += 1) {
      array.push(
        <Page size="ID1" height="200" orientation="landscape">
          <View style={styles.body}>
            <Text style={styles.title}>{invoiceName.name}</Text>

            <View style={styles.subTitle}>
              <View>
                {quantityPDF !== undefined && (
                  <Text>
                    Volumes: {i}/{invoice.quantidade_volumes}
                  </Text>
                )}
              </View>
              <Text style={styles.marginWeight}>Peso Bruto: {weight}kg</Text>
            </View>

            <View style={styles.subTitle}>
              <View>
                <Text>Data de emissão: {productDate}</Text>
              </View>
              <Text>DANFE: {invoice.model}</Text>
            </View>
            <View style={styles.foot}>
              <Text>Destinatário: {invoicePartner.name}</Text>
              <Text>Transportadora: {shipping.name}</Text>
            </View>
          </View>
        </Page>
      );
    }

    return array.map((pagEtiqueta) => pagEtiqueta);
  }
  return <Document>{QuantityPDF()}</Document>;
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 20,
    paddingHorizontal: 20,
    width: '100%',
  },

  title: {
    textTransform: 'uppercase',
    paddingBottom: 10,
    fontSize: 12,
  },

  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 8,

    paddingTop: 5,
    paddingRight: 40,
  },

  foot: {
    paddingTop: 15,
    fontSize: 8,
  },

  productTitle: {
    fontWeight: 'bold',
  },

  product: {
    display: 'flex',
    flexDirection: 'row',
  },

  marginWeight: {
    paddingLeft: 25,
  },
});

PDFCreate.propTypes = {
  invoice: PropTypes.element.isRequired,
  invoiceName: PropTypes.element.isRequired,
  invoicePartner: PropTypes.element.isRequired,
  quantityPDF: PropTypes.element.isRequired,
  weight: PropTypes.element.isRequired,
  shipping: PropTypes.element.isRequired,
  productDate: PropTypes.element.isRequired,
};

export default PDFCreate;
