import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import api from '../../services/api';

import {
  Wrapper,
  Container,
  Selection,
  Etiqueta,
  SubTitle,
  Foot,
} from './styles';

import PDFExport from '../../Components/PDF_Invoice/PDFExport';

function Invoice() {
  const { token } = useParams();

  const [invoice, setInvoice] = useState([]);
  const [invoiceName, setInvoiceName] = useState([]);
  const [invoicePartner, setInvoicePartner] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [productDate, setProductDate] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInvoice() {
      const response = await api.get(`/invoices/${token}/`);
      const { data } = response;
      setInvoice(data);
      setInvoiceName(data.company_id);
      setInvoicePartner(data.partner_id);
      setShipping(data.transportadora_id);

      const formattedDate = format(
        new Date(data.data_emissao),
        "dd'/'MM'/'yyyy'"
      );
      setProductDate(formattedDate);

      setLoading(true);
    }

    loadInvoice();
  }, [token]);

  return (
    <Wrapper>
      {loading ? (
        <Container>
          <Selection />

          <Etiqueta>
            <h1>{invoiceName.name}</h1>
            <SubTitle>
              {invoice.quantidade_volumes &&
                invoice.peso_bruto &&
                invoice.transportadora_id.name !== undefined && (
                  <>
                    <p>Volumes: 1/{invoice.quantidade_volumes}</p>
                    <p>Peso Bruto: {invoice.peso_bruto}kg</p>
                  </>
                )}
            </SubTitle>
            <SubTitle>
              <p>Data de emissão: {productDate}</p>
              <p>DANFE: {invoice.model}</p>
            </SubTitle>
            <Foot>
              <p>Transportadora: {shipping.name}</p>
              <p>Destinatário: {invoicePartner.name}</p>
            </Foot>
          </Etiqueta>

          <PDFExport quantityPDF={invoice.quantidade_volumes} />
        </Container>
      ) : (
        ''
      )}
    </Wrapper>
  );
}

export default Invoice;
