import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import PropTypes from 'prop-types';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { AiOutlineFilePdf, AiOutlineArrowLeft } from 'react-icons/ai';
import PDFCreate from './PDFCreate';

import api from '../../services/api';

import { Container, Back } from './styles';

function PDFExport({ quantityPDF }) {
  const { token } = useParams();

  const [companyName, setCompanyName] = useState([]);

  const [product, setProduct] = useState([]);
  const [productBarcode, setProductBarcode] = useState([]);
  const [productTmpl, setProductTmpl] = useState([]);
  const [productDate, setProductDate] = useState();

  const [loadingButton, setLoadingButton] = useState(false);
  const [barcodeNull, setBarcodeNull] = useState(null);

  useEffect(() => {
    async function loadProduct() {
      const response = await api.get(`/products/${token}/`);
      const { data } = response;

      setCompanyName(data[0].company_id.name);

      setProduct(data[0]);
      setProductBarcode(data[0].product_id);
      setProductTmpl(data[0].product_id.product_tmpl_id);

      const formattedDate = format(new Date(data[0].date), "dd'/'MM'/'yyyy'");
      setProductDate(formattedDate);

      setBarcodeNull(data[0].product_id.barcode);

      setLoadingButton(true);
    }

    loadProduct();
  }, [token]);

  return (
    <>
      <Container>
        {loadingButton ? (
          <button>
            <AiOutlineFilePdf size="1.5em" style={{ color: '#fff' }} />
            <PDFDownloadLink
              document={
                <PDFCreate
                  data={product}
                  productTmpl={productTmpl}
                  productBarcode={productBarcode}
                  productDate={productDate}
                  barcodeNull={barcodeNull}
                  quantityPDF={quantityPDF}
                  companyName={companyName}
                />
              }
              fileName="EtiquetaProduto.pdf"
              style={{ textDecoration: 'none', color: '#fff', width: '120px' }}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download Pdf'
              }
            </PDFDownloadLink>
          </button>
        ) : (
          ''
        )}

        <Back>
          <AiOutlineArrowLeft size="2em" color="#535353" />
          <a href="/">Voltar</a>
        </Back>
      </Container>
    </>
  );
}

PDFExport.propTypes = {
  quantityPDF: PropTypes.element.isRequired,
};

export default PDFExport;
