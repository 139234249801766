import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Selection = styled.div`
  h2 {
    text-transform: capitalize;
    color: #312f2f;
    padding-bottom: 10px;
  }
`;

export const Etiqueta = styled.div`
  background-color: #fff;
  margin-top: 50px;
  box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  padding: 40px;

  h1 {
    padding-bottom: 20px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    padding-top: 5px;
    font-size: 17px;
  }
`;

export const Foot = styled.div`
  padding-top: 30px;
  font-size: 17px;
`;
