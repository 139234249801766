import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import api from '../../services/api';

import { Wrapper, Container, Logo, Title, Buttons } from './styles';
import LogoEquipe from '../../assets/logo_equipe.png';

function Home() {
  const { token } = useParams();
  const [product, setProduct] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadProduct() {
      const response = await api.get(`/products/${token}`);
      const { data } = response;
      setProduct(data[0]);
      setLoading(true);
    }

    loadProduct();
  }, [product, token]);

  useEffect(() => {
    async function loadInvoice() {
      const response = await api.get(`/invoices/${token}`);
      const { data } = response;
      setInvoice(data[0]);
      setLoading(true);
    }

    loadInvoice();
  }, [invoice, token]);

  return (
    <Wrapper>
      <Container>
        <Logo>
          <img src={LogoEquipe} alt="Imagem Equipe Remota" />
        </Logo>
        <Title>Qual etiqueta você deseja imprimir?</Title>
        <Buttons>
          {loading ? (
            <>
              {' '}
              <a href={`/produto/${token}`}>
                <button id="btn_product">Etiqueta do Produto</button>
              </a>
              <a href={`/notafiscal/${token}}`}>
                <button id="btn_note">Nota Fiscal</button>
              </a>{' '}
            </>
          ) : (
            ''
          )}
        </Buttons>
      </Container>
    </Wrapper>
  );
}

export default Home;
