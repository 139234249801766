import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 260px;
    height: 60px;
    border: none;
    background-color: #fbaa51;
    transition: 0.3s;

    :hover {
      background-color: #e88c27;
    }
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;

  > a {
    font-size: 17px;
    color: #535353;
    transition: 0.3s;

    :hover {
      color: #c1bfbf;
    }
  }
`;
