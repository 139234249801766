import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import JsBarcode from 'jsbarcode';

import api from '../../services/api';

import { Wrapper, Container, Etiqueta, SubTitle, Barcode } from './styles';

import PDFExport from '../../Components/PDF_Product/PDFExport';

function ProductLabel() {
  const { token } = useParams();

  const [companyName, setCompanyName] = useState([]);

  const [product, setProduct] = useState([]);
  const [productBarcode, setProductBarcode] = useState([]);
  const [productTmpl, setProductTmpl] = useState([]);
  const [productDate, setProductDate] = useState();

  const [loading, setLoading] = useState(false);

  const [barcodeNull, setBarcodeNull] = useState(null);
  const [quantityPDF, setQuantityPDF] = useState();

  useEffect(() => {
    async function loadProduct() {
      const response = await api.get(`/products/${token}/`);
      const { data } = response;

      setCompanyName(data[0].company_id.name);

      setProduct(data[0]);
      setProductBarcode(data[0].product_id);
      setProductTmpl(data[0].product_id.product_tmpl_id);

      console.log(typeof data[0].date);

      const formattedDate = format(new Date(data[0].date), "dd'/'MM'/'yyyy'");
      setProductDate(formattedDate);

      setBarcodeNull(data[0].product_id.barcode);

      setLoading(true);
    }

    loadProduct();
  }, [token]);

  const canvas = document.createElement('canvas');
  JsBarcode(canvas, `${productBarcode.barcode}`, {
    width: 2,
    height: 40,
  });
  const barcode = canvas.toDataURL();

  return (
    <Wrapper>
      {loading ? (
        <Container>
          <div className="quantity_pdf">
            <h2>Quantas etiquetas deseja imprimir?</h2>
            <input
              type="text"
              placeholder="Digite aqui..."
              value={quantityPDF}
              onChange={(e) => setQuantityPDF(e.target.value)}
            />
          </div>

          <Etiqueta>
            <h1>{companyName}</h1>
            <SubTitle>
              <p>Produto: {productTmpl.name} </p>
              <p>Quantidade/Lote: {product.qty_done} </p>
            </SubTitle>
            <Barcode>
              {barcodeNull !== null && (
                <img src={barcode} alt="Código de Barras" />
              )}
              <p>Data de Emissão: {productDate}</p>
            </Barcode>
          </Etiqueta>

          {quantityPDF && <PDFExport quantityPDF={quantityPDF} />}
        </Container>
      ) : (
        ''
      )}
    </Wrapper>
  );
}

export default ProductLabel;
